<template>
  <div>
    <modalTerms v-model="modalTerms.open" :pageID="modalTerms.pageID" v-on:close="openModalTerms ({open: false, pageID: ''})"></modalTerms>
    <div class="customer-care-content contact-us-page">
      <div class="customer-care-content__header">
        <div class="content-title">
          <h1 class="title title-small">{{ $t('route.contact-us')}}</h1>
          <h3 class="title subtitle">{{ $t('customerCare.contact.our-costumer-service') }}</h3>
        </div>
        <div class="working-hours">
          <b-row>
            <b-col md="8" lg="12">
              <div class="working-hours__cta">
                <div class="working-hours__cta_single">
                  <a :href="'tel:' + $t('customerCare.contact.tel')" class="bcm-link bcm-link-primary">{{ $t('customerCare.contact.call') }}</a>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="customer-care-content__body">
        <div class="contact-form">
          <div class="form-intro">
            <h5 class="intro-title">{{ $t('customerCare.contact.form-intro-title')}}</h5>
            <p class="intro-subtitle">{{ $t('customerCare.contact.form-intro-subtitle')}}</p>
          </div>
          <form @submit.prevent="handleSubmit" ref="form" method="POST" :action="inputId.endpoint" class="form-contact">
            <div class="mandatory-field">
              <span class="t-small">*{{ $t('mandatory-field') }}</span>
            </div>
            <b-row>
              <b-col>
                <b-form-group id="groupFirstname">
                  <base-input
                    :name="inputId.idFirstName"
                    v-validate="'required'"
                    v-model="form.firstname"
                    type="text"
                    :autofocus="true"
                    :placeholder="'* ' +$t('address.FirstName')"
                    :validations="[
                      {
                        condition: errors.has(inputId.idFirstName),
                        text: errors.first(inputId.idFirstName)
                      }
                    ]"
                  ></base-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="groupLastName">
                  <base-input
                    :name="inputId.idLastName"
                    v-validate="'required'"
                    v-model="form.lastname"
                    type="text"
                    :placeholder="'* ' +$t('address.LastName')"
                    :validations="[
                      {
                        condition: errors.has(inputId.idLastName),
                        text: errors.first(inputId.idLastName)
                      }
                    ]"
                  ></base-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="groupCountry">
                  <BaseSelect
                  :name="inputId.idCountry"
                  v-model="form.country"
                  v-validate="'required'"
                  :placeholder="'* ' +$t('address.Country')"
                  :options="optionsCountries"
                  :reduce="opt => opt.value"
                  :validations="[
                    {
                      condition: errors.has(inputId.idCountry),
                      text: errors.first(inputId.idCountry)
                    }
                  ]"
                  >
                  </BaseSelect>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="groupCity">
                  <base-input
                    :name="inputId.idCity"
                    v-validate="'required'"
                    v-model="form.city"
                    type="text"
                    :placeholder="'* ' +$t('address.City')"
                    :validations="[
                      {
                        condition: errors.has(inputId.idCity),
                        text: errors.first(inputId.idCity)
                      }
                    ]"
                  ></base-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="groupEmail">
                  <base-input
                    :name="inputId.idEmail"
                    v-validate="'required|email'"
                    v-model="form.email"
                    type="email"
                    :placeholder="'* ' + $t('address.Email')"
                    :validations="[
                      {
                        condition: errors.has(inputId.idEmail),
                        text: errors.first(inputId.idEmail)
                      }
                    ]"
                  ></base-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="groupContactReason">
                  <BaseSelect
                    v-model="form.reason"
                    :name="inputId.idCaseReason"
                    v-validate="'required'"
                    :placeholder="'* ' + $t('customerCare.contact.contact_reason')"
                    :options="optionsReason"
                    :validations="[
                      {
                        condition: errors.has(inputId.idCaseReason),
                        text: errors.first(inputId.idCaseReason)
                      }
                    ]"
                  >
                  </BaseSelect>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="groupOrderNumber">
                  <base-input
                    :name="inputId.idOrderNumber"
                    v-validate="orderRequired"
                    v-model="form.ordernumber"
                    type="text"
                    :placeholder="(orderRequired ? '* ' : '') + $t('customerCare.contact.order_number')"
                    :validations="[
                      {
                        condition: errors.has(inputId.idOrderNumber),
                        text: errors.first(inputId.idOrderNumber)
                      }
                    ]"
                  ></base-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="groupPersonalMessage">
                  <BaseTextarea
                    :name="inputId.idDescription"
                    v-model="form.message"
                    v-validate="'required'"
                    :placeholder="'* ' + $t('customerCare.contact.write_message')"
                    :rows="12"
                    :number="true"
                    :validations="[
                      {
                        condition: errors.has(inputId.idDescription),
                        text: errors.first(inputId.idDescription)
                      }
                    ]"
                  ></BaseTextarea>
                  <p class="cl-gray mt-2 mb-0">{{ totalcount }} {{ $t('customerCare.contact.charachters_left') }}</p>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>

              <div class="form-end-row">
                <b-col md="10">
                  <div class="form-end-intro">
                    <p>
                      {{$t('customerCare.contact.privacy-info-transmitted')}}
                    </p>
                  </div>
                  <b-form-group id="groupCheckBox" class="privacy-check">
                    <base-check-box
                      name="privacy"
                      v-validate="'required:true'"
                      v-model="form.privacyChecked"
                      :label="labelLinkPrivacy"
                      :validations="[
                        {
                          condition: errors.has('privacy'),
                          text: errors.first('privacy')
                        }
                      ]"
                    >
                    </base-check-box>
                  </b-form-group>
                </b-col>
                <b-col>
                  <input type=hidden name="orgid" :value="inputId.valueOrgId">
                  <input type=hidden name="retURL" :value="inputId.valueRetURL.replace('{{storeViewCode}}', this.$store.state.ui.storeViewCode)">
                  <input type=hidden id="idLanguage" name="inputId.idLanguage" value="English">
                  <button type="submit" class="btn btn-primary btn-black submit-form">{{ $t('send') }}</button>
                </b-col>
              </div>
            </b-row>
          </form>
        </div>
      </div><!-- end body -->
      <div class="customer-care-content__footer">
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/Form/BaseInput'
import BaseSelect from '@/theme/components/Form/BaseSelect'
import BaseTextarea from '@/components/Form/BaseTextarea'
import BaseCheckBox from '@/components/Form/BaseCheckBox'
import ModalTerms from '@/theme/components/Modal/Terms'
import Config from '@/config'
import { scrollTo } from '@/helpers/utils'
import { toast } from '@/modules/notifications'
import { pathTranslate } from '@/helpers/routeHelpers'
import get from 'lodash/get'
import $ from 'jquery'

export default {
  name: 'ContactUs',
  inject: ['$validator'],
  data () {
    return {
      modalTerms: {
        open: false,
        pageID: ''
      },
      BrowseText: '',
      form: {
        firstname: '',
        lastname: '',
        email: '',
        country: '',
        city: '',
        reason: null,
        message: '',
        ordernumber: '',
        privacyChecked: false
      },
      inputId: {}
    }
  },
  methods: {
    openChat () {
      window.lz_chat_open && window.lz_chat_open()
    },
    openModalTerms ({ open, pageID }) {
      this.modalTerms = {
        open,
        pageID
      }
    },
    clearForm () {
      this.form.firstname = this.form.lastname = this.form.email = this.form.country = this.form.message = this.form.ordernumber = this.form.city = ''
      this.form.privacyChecked = false
      this.form.reason = null
      this.form.uploadfile = []
      this.$validator.reset()
    },
    handleSubmit (evt) {
      const _this = this
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          _this.$store.commit('ui/showLoader')
          _this.$refs.form.submit()
        } else {
          scrollTo({
            className: 'is-invalid'
          })
        }
      })
    }
  },
  computed: {
    totalcount () {
      let maxCount = 400
      maxCount = maxCount - this.form.message.length
      return maxCount
    },
    optionsReason () {
      const _this = this
      return Config.Theme.contactUs.reasonSalesForce.map(item => ({
        ...item,
        label: _this.$t(`customerCare.contact.reasons.option.${item.label}`)
      }))
    },
    optionsCountries () {
      return this.$store.state.checkout.countries.map(country => ({
        value: country.id,
        label: country.full_name_locale
      }))
    },
    orderRequired () {
      if (this.form.reason && this.form.reason.mandatory && this.form.reason.mandatory.includes('orderid')) {
        return 'required'
      }
      return ''
    },
    labelLinkPrivacy () {
      let linkPrivacy = this.$router.resolve({ name: 'genericTerms', params: { slug: pathTranslate({ path: 'privacy-policy' }), lang: this.$store.state.ui.storeViewCode } }).href
      return this.$t('customerCare.contact.privacy', { linkPrivacy })
    },
    getWebsiteLanguage () {
      let language = get(this.$store, 'state.storeConfig.config.code', undefined)
      if (language) {
        switch (language.slice(-2)) {
          case 'es':
            language = 'Spanish'
            return language
          case 'it':
            language = 'Italian'
            return language
          case 'fr':
            language = 'French'
            return language
          case 'de':
            language = 'German'
            return language
          case 'en':
            language = 'English'
            return language
          case 'zh':
            language = 'Chinese'
        }
      }
    }
  },
  mounted () {
    this.$store.dispatch('checkout/loadCountries')
    this.clearForm()
    const _this = this
    $(document).on('click', 'a.labelLinkPrivacy', function (e) {
      e.preventDefault()
      _this.openModalTerms({ open: true, pageID: 'terms1' })
    })
    if (this.$route.query.wtc && this.$route.query.wtc === 'true') {
      this.clearForm()
      this.$router.replace(this.$route.path)
      toast.success(this.$t('Request successful'))
    }
  },
  created () {
    if (process.env.VUE_APP_ENVIRONMENT === 'PRODUCTION') {
      this.inputId = Config.API.contactUsFormSalesForce.prodSandBox
    } else this.inputId = Config.API.contactUsFormSalesForce.devSandBox
  },
  components: {
    BaseInput,
    BaseSelect,
    BaseTextarea,
    BaseCheckBox,
    ModalTerms
  }
}
</script>
